import "./App.css";
import Routes from "./Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./AuthProvider";
import { Layout } from "./Layout";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { Notifications } from "@mantine/notifications";

import { createTheme, MantineProvider } from "@mantine/core";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  },
});

const theme = createTheme({
  /** Put your mantine theme override here */
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications />
        <AuthProvider>
          <Layout>
            <Routes />
          </Layout>
        </AuthProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
