import { useState, useEffect, useMemo } from "react";

export const useLocalStorage = (key: string, initialValue: string) => {
  const initial = useMemo(() => {
    // @ts-ignore: TODO dom lib not working
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  }, [key]);

  const [value, setValue] = useState(initial);

  useEffect(() => {
    // @ts-ignore: TODO dom lib not working
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
