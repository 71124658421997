import { useUploadFileToMatter } from "../../api/smokeballApi";
import { useState } from "react";
import { WebcamCapture } from "./WebcamCapture";
import { downloadPdf, imagesToPdf } from "../../util/pdf";
import { MatterForm } from "./MatterForm";
import { removeAt } from "../../util/array";
import { showError, showSuccess } from "../../util/notifications.ts";
import { Contact, Matter } from "../../types/smokeball.ts";
import { getContactLabel } from "../../domain/contacts.ts";

export const Scanner = () => {
  const uploadFileToMatterMutation = useUploadFileToMatter();

  const [screenshots, setScreenshots] = useState<string[]>([]);

  const handleDownloadClick = async () => {
    try {
      if (!screenshots.length) {
        throw new Error("Take at least one screenshot");
      }

      const pdf = await imagesToPdf(screenshots);
      downloadPdf(pdf);
    } catch (ex: any) {
      console.error(ex);
      showError(ex.message);
    }
  };

  const handleSubmit = async (contact: Contact, matter: Matter) => {
    try {
      if (!screenshots.length) {
        throw new Error("Take at least one screenshot");
      }

      const pdf = await imagesToPdf(screenshots);
      await uploadFileToMatterMutation.mutateAsync({
        matterId: matter.id,
        file: pdf.output("blob"),
      });
      setScreenshots([]);
      showSuccess(`Uploaded pdf to ${getContactLabel(contact)}'s matter`);
    } catch (ex: any) {
      console.error(ex);
      showError(ex.message);
    }
  };

  return (
    <div className="flex flex-col lg:h-screen lg:flex-row">
      <div className="flex-auto bg-gray-400 h-[400px] lg:h-auto max-h-screen">
        <WebcamCapture
          screenshots={screenshots}
          onCapture={(screenshot) => {
            setScreenshots((s) => [...s, screenshot]);
          }}
          onRemovePage={(i) => {
            setScreenshots((ss) => removeAt(ss, i));
          }}
        />
      </div>

      <div className="lg:w-[20%] lg:min-w-[300px]">
        <MatterForm
          isSubmitting={uploadFileToMatterMutation.isLoading}
          onDownloadClick={handleDownloadClick}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};
