import jsPDF from "jspdf";
import { fitImage, getImageDimensionsFromBase64 } from "./image.ts";
import { getIsLandscape, newSize } from "./size.ts";

export const imagesToPdf = async (images: string[]): Promise<jsPDF> => {
  if (images.length === 0) {
    throw new Error("No images to save");
  }

  const firstDims = await getImageDimensionsFromBase64(images[0]);
  const pdfDoc = new jsPDF({
    orientation: getIsLandscape(firstDims) ? "l" : "p",
  });
  for (let index = 0; index < images.length; index++) {
    const image = images[index];
    const imageSize = await getImageDimensionsFromBase64(image);

    if (index !== 0) {
      pdfDoc.addPage(undefined, getIsLandscape(imageSize) ? "l" : "p");
    }
    const pageSize = getIsLandscape(imageSize)
      ? newSize(297, 210)
      : newSize(210, 297);

    const scaledImageSize = fitImage(pageSize, imageSize);

    pdfDoc.addImage(
      image,
      "JPG",
      (pageSize.width - scaledImageSize.width) / 2,
      (pageSize.height - scaledImageSize.height) / 2,
      scaledImageSize.width,
      scaledImageSize.height,
      undefined,
      "MEDIUM",
    );
  }

  return pdfDoc;
};

export const downloadPdf = (pdfDoc: jsPDF) => {
  const blob = pdfDoc.output("blob");
  const url = URL.createObjectURL(blob);

  // @ts-ignore: TODO dom lib not working
  const a = document.createElement("a");
  a.href = url;
  a.download = "webcam-screenshots.pdf";
  a.click();

  URL.revokeObjectURL(url);
};
