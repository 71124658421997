import React, { createContext, useCallback, useContext } from "react";
import { useFetchAuthStatus, useLogoutMutation } from "./api/smokeballApi";
import { buildUrl } from "./util/url.ts";

const AuthContext = createContext<{
  isLoggedIn: boolean;
  login: () => void;
  logOut: () => void;
}>({
  isLoggedIn: false,
  login: () => {},
  logOut: () => {},
});

// Create a provider component
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, error } = useFetchAuthStatus();

  const logoutMutation = useLogoutMutation();

  const login = useCallback(() => {
    // @ts-ignore: TODO dom lib not working
    window.location.href = buildUrl(
      "https://auth.smokeball.com.au/oauth2/authorize",
      {
        response_type: "code",
        client_id: "nv57uto1d0k29hfbq26r2doo8",
        scope: "openid",
        // @ts-ignore: TODO dom lib not working
        redirect_uri: window.location.origin + "/api/smokeball/oauth-callback",
      },
    );
  }, []);

  const logOut = useCallback(() => {
    logoutMutation.mutate();
  }, [logoutMutation]);

  if (!data && error) {
    return <div>Failed to fetch auth data</div>;
  }
  if (!data) {
    return <div>Checking if logged in</div>;
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: data.isLoggedIn, login, logOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
