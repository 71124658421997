import { apiCallData } from "../util/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Contact, Matter, SmokeballResponse } from "../types/smokeball";
import { useCallback } from "react";
import { contactsResponseMock } from "./mocks/contact";
import { QueryClient } from "react-query/core";

const mocksEnabled = true;

const basicQuery = <TResp>(
  queryKey: string[],
  url: string,
  mockResp?: TResp,
) => {
  const queryFn = () =>
    mocksEnabled && mockResp
      ? Promise.resolve(mockResp)
      : apiCallData<TResp>("GET", url);

  return () =>
    useQuery({
      queryKey,
      queryFn,
    });
};

const basicMutation = <TResp>(
  mutationKey: string[],
  url: string,
  mutationCallback?: (queryClient: QueryClient, data: TResp) => void,
) => {
  const mutationFn = () => apiCallData<TResp>("POST", url);

  return () => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn,
      mutationKey,
      onSuccess: mutationCallback
        ? (data, variables) => mutationCallback(queryClient, data)
        : undefined,
    });
  };
};

export const useFetchAuthStatus = basicQuery<{ isLoggedIn: boolean }>(
  ["authStatus"],
  "/api/smokeball/status",
);

export const useLogoutMutation = basicMutation<{ isLoggedIn: boolean }>(
  ["authStatus"],
  "/api/smokeball/logout",
  (queryClient: QueryClient, data: { isLoggedIn: boolean }) => {
    queryClient.setQueryData(["authStatus"], data);
  },
);

export const useFetchContacts = basicQuery<SmokeballResponse<Contact[]>>(
  ["contacts"],
  "/api/smokeball/p/contacts?ExcludeDeletedContacts=true",
  contactsResponseMock,
);

export const useFetchMattersByContact = (contactId: string | undefined) => {
  const queryFn = useCallback(
    () =>
      apiCallData<SmokeballResponse<Matter[]>>(
        "GET",
        "/api/smokeball/p/matters",
        {
          params: { ContactId: contactId },
        },
      ),
    [contactId],
  );

  return useQuery({
    queryKey: [contactId],
    queryFn,
    enabled: !!contactId,
  });
};

type UploadMatterRequest = {
  fileName: string;
  folderId: string;
  userId: string;
  folderPath: string;
  fileAdditionalData: {
    property1: null;
    property2: null;
  };
  dateCreated: string;
  dateModified: string;
};

type UploadMatterResponse = {
  fileId: string;
  uploadUrl: string;
  expiry: string;
};

export const useUploadFileToMatter = () => {
  const mutationFn = useCallback(
    async ({ matterId, file }: { matterId: string; file: Blob }) => {
      const fileName = `scanTest-${new Date().getTime()}.pdf`;
      const { uploadUrl } = await apiCallData<UploadMatterResponse>(
        "POST",
        `/api/smokeball/p/matters/${encodeURIComponent(
          matterId,
        )}/documents/files`,
        {
          body: {
            // TODO
            fileName,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      await apiCallData<UploadMatterResponse>("PUT", uploadUrl, {
        body: file,
        headers: {
          "Content-Type": "application/pdf",
        },
      });
    },
    [],
  );

  return useMutation({
    mutationFn,
    mutationKey: ["matterId"],
  });
};
