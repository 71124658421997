import "./App.css";
import { LoginPage } from "./pages/Login";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Scanner } from "./pages/Scanner";
import { PrivateRoute } from "./containers/PrivateRoute";
import { Home } from "./pages/Home";

const Routes = () => {
  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/scanner",
      element: (
        <PrivateRoute>
          <Scanner />
        </PrivateRoute>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
