import { useAuth } from "./AuthProvider";
import { Button } from "@mantine/core";
import React from "react";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn, logOut } = useAuth();

  return (
    <div>
      {children}
      {isLoggedIn && (
        <div className="lg:absolute lg:top-1 lg:right-1 flex justify-end">
          <Button type="button" onClick={logOut} size="xs" variant="subtle">
            Logout
          </Button>
        </div>
      )}
    </div>
  );
};
