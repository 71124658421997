import { useEffect, useMemo, useState } from "react";
import { Select } from "@mantine/core";
import { useLocalStorage } from "../../util/localStorage.ts";

export const useCameraList = () => {
  const [selectedCameraId, setSelectedCamera] = useLocalStorage(
    "selectedCamera",
    "",
  );
  // @ts-ignore: TODO dom lib not working
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[] | null>(null);

  useEffect(() => {
    const getCameraList = async () => {
      try {
        // @ts-ignore: TODO dom lib not working
        const devices: MediaDeviceInfo[] =
          // @ts-ignore: TODO dom lib not working
          await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(
          (device) => device.kind === "videoinput",
        );
        setCameraList(cameras);
        if (
          cameras.length > 0 &&
          (!selectedCameraId ||
            !cameras.find((c) => c.deviceId === selectedCameraId))
        ) {
          setSelectedCamera(cameras[0].deviceId);
        }
      } catch (error) {
        console.error("Error enumerating devices:", error);
      }
    };
    getCameraList();
  }, []);

  const selectedCamera = useMemo(
    () =>
      selectedCameraId && cameraList
        ? cameraList.find((c) => c.deviceId === selectedCameraId)
        : null,
    [cameraList, selectedCameraId],
  );

  const isSelfieCam = useMemo(() => {
    return selectedCamera
      ? selectedCamera.label.match(/front|webcam/i)
      : undefined;
  }, [selectedCamera]);

  return {
    cameraList,
    selectedCamera: !!selectedCameraId ? selectedCameraId : undefined,
    isSelfieCam,
    cameraListNode:
      (cameraList?.length || 0) > 1 ? (
        <Select
          value={selectedCameraId || ""}
          onChange={(val) => setSelectedCamera(val || "")}
          data={
            cameraList
              ? cameraList.map((camera) => ({
                  value: camera.deviceId,
                  label: camera.label,
                }))
              : []
          }
        ></Select>
      ) : null,
  };
};
