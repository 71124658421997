import _ from "lodash/fp";
import { buildUrl } from "./url";
import { QueryStringParams } from "./url";

export type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type Init = Omit<RequestInit, "method" | "url" | "body"> & {
  params?: QueryStringParams;
  body?: string | object;
};

const getIsJsonContentType = (contentType: string | null) => {
  if (!contentType) return false;

  return !!contentType.split(";").find((t) => {
    const type = t.trim().toLowerCase();
    return type === "application/json";
  });
};

const adaptResponse = async (response: Response) => {
  const contentType = response.headers.get("content-type");
  const isJsonResp = getIsJsonContentType(contentType);

  return {
    data: await (isJsonResp ? response.json() : response.text()),
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    redirected: response.redirected,
    url: response.url,
  };
};

const doFetch = async <T>(
  method: Method,
  url: string,
  init: Omit<Init, "params">,
): Promise<{
  data: T;
  status: number;
  statusText: string;
  redirected: boolean;
  url: string;
}> => {
  const response = await fetch(url, {
    ...init,
    method,
    body:
      typeof init.body === "string" ||
      init.body instanceof FormData ||
      init.body instanceof Blob
        ? init.body
        : JSON.stringify(init.body),
  });

  if (response.ok) {
    return await adaptResponse(response);
  }

  throw Object.assign(
    new Error(response.statusText || `Error status: ${response.status}`),
    {
      response: await adaptResponse(response),
    },
  );
};

export const apiCall = async <T>(
  method: Method,
  url: string,
  opts: Init = {},
) => {
  try {
    let newUrl = opts.params ? buildUrl(url, opts.params) : url;

    return await doFetch<T>(method, newUrl, _.omit("params", opts));
  } catch (error) {
    const ex = error as any;
    if (ex.response?.data?.error) {
      throw Object.assign(new Error(ex.response.data.error), {
        response: ex.response,
      });
    }
    throw ex;
  }
};

export const apiCallData = <T>(method: Method, url: string, opts: Init = {}) =>
  apiCall<T>(method, url, opts).then((r) => r.data);
