import { useMemo, useState } from "react";
import { useWindowEvent } from "@mantine/hooks";

export const useIsWindowFocused = () => {
  // @ts-ignore: TODO dom lib not working
  const initialFocus = useMemo(() => document.hasFocus(), []);
  const [isFocused, setIsFocused] = useState<boolean>(initialFocus);

  useWindowEvent("focus", () => {
    setIsFocused(true);
  });

  useWindowEvent("blur", () => {
    setIsFocused(false);
  });
  return isFocused;
};
