import * as _ from "lodash/fp";
import qs from "query-string";

type QueryStringValue =
  | string
  | number
  | null
  | undefined
  | string[]
  | number[]
  | Date
  | Date[]
  | boolean;
export type QueryStringParams = { [key: string]: QueryStringValue };

export const stringifyQueryParams = (params: QueryStringParams) => {
  if (!params) return "";

  const val = _.pickBy((val) => val != null, params);
  return qs.stringify(val);
};

export const buildUrl = (url: string, params: QueryStringParams = {}) => {
  const queryString = stringifyQueryParams(params);

  return !queryString.length ? url : `${qs.parseUrl(url).url}?${queryString}`;
};
