import { Contact, SmokeballResponse } from "../../types/smokeball";

export const contactsResponseMock: SmokeballResponse<Contact[]> = {
  href: "https://api.smokeball.com.au/contacts",
  offset: 0,
  limit: 500,
  size: 454,
  first: {
    href: "https://api.smokeball.com.au/contacts?limit=500",
    rel: "collection",
  },
  last: {
    href: "https://api.smokeball.com.au/contacts?limit=500",
    rel: "collection",
  },
  value: [
    {
      href: "https://api.smokeball.com.au/contacts/5e64a787-74e8-4417-bfbe-09d6de0080fd",
      id: "5e64a787-74e8-4417-bfbe-09d6de0080fd",
      versionId: "c5a3c1a8-814a-4edc-8768-f20452a29db0",
      person: {
        title: "Mr",
        firstName: "Victor",
        middleName: "Valco",
        lastName: "Vendor",
        nameSuffix: "",
        residentialAddress: {
          streetNumber: "88",
          streetName: "Marble",
          streetType: "Parade",
          city: "Melbourne",
          state: "VIC",
          zipCode: "3000",
          country: "Australia",
          poBox: {},
        },
        phone: {
          number: "03 3888 8888",
        },
        phone2: {
          number: "03 8888 8888",
        },
        fax: {},
        cell: {
          number: "04 8888 8888",
        },
        email: "vvv@mailinator.com",
        notes: "",
        gender: "Male",
        birthDate: "1988-08-08T00:00:00",
        identificationNumberType: "SSN",
        identificationNumber: "",
      },
      isDeleted: false,
      lastUpdated: 638133177038115987,
    },
  ],
};
