import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "@mantine/core";
import { useIsWindowFocused } from "../../util/dom";
import { flipImage } from "../../util/image.ts";
import { useCameraList } from "./useCameraList.tsx";

export const WebcamCapture = ({
  onCapture,
  screenshots,
  onRemovePage,
}: {
  onCapture: (snapshot: string) => void;
  screenshots: string[];
  onRemovePage: (index: number) => void;
}) => {
  const webcamRef = useRef<Webcam>(null);

  const isWindowFocused = useIsWindowFocused();

  const [selectedPage, setSelectedPage] = useState<number | null>(null);

  const { selectedCamera, cameraListNode, isSelfieCam } = useCameraList();

  useEffect(() => {
    if (
      selectedPage &&
      screenshots.length &&
      selectedPage >= screenshots.length
    ) {
      setSelectedPage(screenshots.length - 1);
    } else if (selectedPage && !screenshots.length) {
      setSelectedPage(null);
    }
  }, [screenshots.length]);

  const handleCapture = useCallback(async () => {
    if (!webcamRef.current) return; // Shouldn't happen
    const base64 = webcamRef.current.getScreenshot();
    if (!base64) {
      throw new Error("Unable to get screenshot");
    }
    onCapture(isSelfieCam ? await flipImage(base64, true) : base64);
  }, [onCapture]);

  return (
    <div className="flex flex-1">
      <div className="flex flex-1 items-center justify-center relative">
        <div className="absolute left-2 top-2 bg-white z-10">
          {cameraListNode}
        </div>
        {selectedPage != null ? (
          <div className="flex-1 flex-col h-[400px] lg:h-screen flex items-center justify-center">
            <img
              src={screenshots[selectedPage]}
              alt="Preview"
              className="flex-1 object-contain"
              style={{ height: "calc(100% - 100px)" }}
            />
            <div className="flex flex-0 h-20 justify-center items-center p-2 h-[100px]">
              <Button
                onClick={() => {
                  setSelectedPage(null);
                }}
                variant="transparent"
                color="black"
                className="w-[100%] flex justify-center items-center mr-2"
              >
                Done
              </Button>
              <Button
                color="red"
                onClick={(e) => {
                  if (screenshots.length <= 1) {
                    setSelectedPage(null);
                  } else if (selectedPage >= screenshots.length - 1) {
                    setSelectedPage(screenshots.length - 2);
                  }
                  onRemovePage(selectedPage);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        ) : isWindowFocused && selectedCamera ? (
          <div className="relative">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              mirrored={isSelfieCam}
              forceScreenshotSourceSize={true}
              videoConstraints={{
                deviceId: selectedCamera,
                width: 2016,
                height: 1512,
              }}
              className="flex-1 h-[400px] lg:h-screen"
            />
            <button
              onClick={handleCapture}
              className="absolute bottom-8 left-[calc(50%-25px)] z-10 bg-gray-50 border-black h-[50px] w-[50px] border-2 rounded-full"
            ></button>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="flex flex-col flex-0 w-[120px] items-center mx-2 overflow-y-auto h-[400px] lg:h-screen p-2">
        {screenshots.map((s, i) => (
          <button
            key={i}
            type="button"
            onClick={() => {
              setSelectedPage(i);
            }}
            className={
              "my-2 " +
              (selectedPage === i ? "outline-2 outline-blue-600 outline" : "")
            }
          >
            <img src={s} alt="Screenshot" />
          </button>
        ))}
      </div>
    </div>
  );
};
