import { get, useFormContext } from "react-hook-form";
import { FieldPath, FieldValues } from "react-hook-form";
import { Text } from "@mantine/core";

type Props<TFieldValues extends FieldValues> = {
  fieldPath: FieldPath<TFieldValues> | string;
  style?: React.CSSProperties;
};

export const ErrorMessage = <TFieldValues extends FieldValues>({
  fieldPath,
  style,
}: Props<TFieldValues>) => {
  const { formState } = useFormContext<TFieldValues>();

  const error = get(formState.errors, fieldPath);

  return error ? (
    <Text c="red" style={style}>
      {error.message}
    </Text>
  ) : null;
};
