import { Contact } from "../types/smokeball.ts";

export const getContactLabel = (c: Contact) => {
  if (c.person) {
    return c.person.firstName + " " + c.person.lastName;
  }
  if (c.company) {
    return c.company.name;
  }
  return c.id;
};
