import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // For now, we only have one page. Soon, we can create a home page
    navigate("/scanner");
  });

  return null;
};
