import yflLogo from "./yflLogo.svg";
import { Button } from "@mantine/core";
import { useAuth } from "../../AuthProvider.tsx";

export const LoginPage = () => {
  const { login } = useAuth();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen">
      <img src={yflLogo} alt="Yarra Family Lawyers" width={300} />
      <div className="mt-4">
        <Button type="button" onClick={login}>
          Login
        </Button>
      </div>
    </div>
  );
};
