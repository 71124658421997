import { SelectField } from "../../containers/forms/SelectField";
import { Button } from "@mantine/core";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useFetchContacts,
  useFetchMattersByContact,
} from "../../api/smokeballApi";
import { validationSchema, FormFields } from "./validationSchema";
import { getContactLabel } from "../../domain/contacts";
import { Contact, Matter } from "../../types/smokeball";

export const MatterForm = ({
  onSubmit,
  isSubmitting,
  onDownloadClick,
}: {
  isSubmitting: boolean;
  onDownloadClick: () => void;
  onSubmit: (contact: Contact, matter: Matter) => void;
}) => {
  const form = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
  });

  const selectedContact = form.watch("contactId");

  const { data: contactsData, error } = useFetchContacts();
  const { data: mattersData, error: mattersError } =
    useFetchMattersByContact(selectedContact);

  const contacts = useMemo(
    () =>
      contactsData?.value.map((c) => ({
        value: c.id,
        label: getContactLabel(c),
      })),
    [contactsData],
  );

  const matters = useMemo(
    () => mattersData?.value.map((m) => m.id),
    [mattersData?.value],
  );

  const handleSubmit = (formData: FormFields) => {
    const contact = contactsData?.value.find(
      (c) => c.id === formData.contactId,
    );
    if (!contact) {
      throw new Error("Invalid contact ID " + formData.contactId);
    }

    const matter =
      mattersData?.value.length === 1
        ? mattersData.value[0]
        : formData.matterId
        ? mattersData?.value.find((m) => m.id === formData.matterId)
        : undefined;
    if (!matter?.clientIds.includes(formData.contactId)) {
      throw new Error("Select a matter for the client"); // There must be some stale data
    }

    onSubmit(contact, matter);
  };

  if (!contactsData && error) return <div>Error</div>;
  if (!contactsData) return <div>...</div>;

  return (
    <div className="p-4">
      <Button type="button" onClick={onDownloadClick}>
        Download
      </Button>

      <hr className="my-4" />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <SelectField<FormFields>
            label="Contact"
            placeholder="Pick value"
            data={contacts}
            searchable
            fieldPath="contactId"
            className="mb-4"
          />

          {selectedContact != null && (
            <div className="mb-4">
              {!matters && mattersError ? (
                <div>Failed to load matters</div>
              ) : !matters ? (
                <div>...</div>
              ) : !matters.length ? (
                <div>No matters available for contact</div>
              ) : matters.length === 1 ? (
                <div />
              ) : (
                <SelectField<FormFields>
                  label="Matter"
                  placeholder="Pick value"
                  data={matters}
                  fieldPath="matterId"
                />
              )}
            </div>
          )}

          <Button type="submit" loading={isSubmitting}>
            Upload
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};
