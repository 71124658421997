import { Size } from "../types/dimensions.ts";

export const getImageDimensionsFromBase64 = (
  base64ImageData: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve, reject) => {
    // @ts-ignore: TODO dom lib not working
    const img = new Image();

    img.onload = function () {
      resolve({ width: this.width, height: this.height });
    };

    img.onerror = function () {
      reject("Error loading image");
    };

    img.src = base64ImageData;
  });

export const fitImage = (containerSize: Size, imageSize: Size) => {
  const containerRatio = containerSize.width / containerSize.height;
  const imageRatio = imageSize.width / imageSize.height;

  if (imageRatio > containerRatio) {
    // Image is wider than the container
    const newImageWidth = containerSize.width;
    const newImageHeight = containerSize.width / imageRatio;
    return { width: newImageWidth, height: newImageHeight };
  } else {
    // Image is taller than the container or the same aspect ratio
    const newImageHeight = containerSize.height;
    const newImageWidth = containerSize.height * imageRatio;
    return { width: newImageWidth, height: newImageHeight };
  }
};

export const flipImage = (
  base64Image: string,
  flipHorizontal = false,
  flipVertical = false,
): Promise<string> =>
  new Promise((resolve, reject) => {
    // @ts-ignore: TODO dom lib not working
    const img = new Image();

    img.onload = function () {
      // @ts-ignore: TODO dom lib not working
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");

      if (flipHorizontal) {
        ctx.translate(img.width, 0);
        ctx.scale(-1, 1);
      }
      if (flipVertical) {
        ctx.translate(0, img.height);
        ctx.scale(1, -1);
      }

      ctx.drawImage(img, 0, 0);

      resolve(canvas.toDataURL());
    };
    img.onerror = () => {
      reject("Error flipping the image.");
    };

    img.src = base64Image;
  });
