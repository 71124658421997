import { Select, SelectProps } from "@mantine/core";
import { FieldPath, useController, FieldValues } from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage";

export const SelectField = <TFieldValues extends FieldValues>({
  fieldPath,
  onChange,
  ...rest
}: { fieldPath: FieldPath<TFieldValues> } & SelectProps) => {
  const { field } = useController<TFieldValues>({
    name: fieldPath,
  });

  return (
    <>
      <Select
        onChange={(val) => {
          field.onChange(val);

          if (onChange) {
            onChange(val);
          }
        }}
        {...rest}
      />
      <ErrorMessage fieldPath={fieldPath} />
    </>
  );
};
